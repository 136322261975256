import config from "tsi-common-react/src/config";
import { strings } from "tsi-common-react/src/localization";
import { strToBool } from "tsi-common-react/src/utils/format";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import { t } from "ttag";

config.update({
    ENABLE_CHECKOUT_CAPTCHA: true,
    RETAIL_LOCATOR_API: getPageSetting("retail-locator-api"),
    METRICS_API: getPageSetting("metrics-api"),
    REVIEWS_API: getPageSetting("reviews-api"),
    ENABLE_BASKET_ID: strToBool(getPageSetting("enable-basket-id") || "yes"),
    REVIEWS_DISCLOSURE_SETTINGS: getPageSetting("reviews-disclosures-settings"),
    RETAIL_LOCATOR_BRAND: getPageSetting("retail-locator-brand"),
    GOOGLE_MAPS_API: getPageSetting("google-maps-api-key"),
});

strings.update({
    WEBSITE_SUPPORT_NUMBER: getPageSetting(
        "support-website-phone-number-display",
    ),
    WEBSITE_SUPPORT_NUMBER_LINK: getPageSetting(
        "support-website-phone-number-link",
    ),
    WEBSITE_SUPPORT_CONTACT_HOURS: getPageSetting("support-contact-hours"),
    ORDER_THANK_YOU_B1_CREATE_ACCT_COPY: t`Create an account to easily check order status and get updates on delivery, plus register your products and write reviews for all your orders from one convenient location.`,
    ORDER_THANK_YOU_B1_CREATE_ACCT_SUCCESS: t`Don't forget, you can easily check order status and get updated on delivery, plus register your products and write reviews for all your orders from your <MyOrdersLink>My Orders</MyOrdersLink> page.`,
    ORDER_THANK_YOU_B3_CREATE_ACCT_COPY: t`The email address <UserEmail>EMAIL ADDRESS</UserEmail> is already associated with an account. <LoginLink>Log in</LoginLink> to easily check order status and get updates on delivery, plus register your products and write reviews for all your orders from one convenient location.`,
    ORDER_THANK_YOU_B4_CREATE_ACCT_COPY: t`Don't forget, you can easily check order status and get updates on delivery, plus register your products and write reviews for all your orders from your <MyOrdersLink>My Orders</MyOrdersLink> page.`,
    FINANCING_CARD_NAME: "Stearns & Foster<SUP>®</SUP> Credit Card",
    FINANCING_APPROVAL_INTRO_TEXT: t`You have been pre-qualified for a Stearns & Foster Credit Card with a credit line of <B>up to:</B>`,
    FINANCING_FORTIVA_OFFER_INTRO_TEXT: t`Congratulations! You are pre-qualified for a Fortiva<sup>®</sup> Retail Credit Account with <br /> Stearns & Foster.`,
    FINANCING_APPLY_CTA_HEAD: "No account? Apply today.",
    FINANCING_APPLY_CTA_SUBHEAD:
        "Finance your purchase with a Stearns & Foster Credit Card",
    FINANCING_SHARE_CONTACT_INFO:
        "By submitting your application, you acknowledge and agree that Stearns & Foster may send, email, or call you with information regarding " +
        "our financing program, as well as their products over the next 30 days.",
    FINANCING_PURCHASE_PRICE_NOTICE: t`
        This is not a request for a specific credit limit. You agree that you may receive and accept a credit
        limit less than the purchase amount. Providing an amount helps ensure your purchase expectations are
        considered as part of your application for credit, when possible. For example, Stearns & Foster mattress sets
        range from $1,500–$12,500.
    `,
    FINANCING_PRINT_APPLY_FORM_HEAD:
        "Stearns & Foster Online Credit Application - Printable Review",
    FINANCING_APP_INTRO_HEAD:
        "Important Information About Procedures For Opening A New Account",
    FINANCING_APP_INTRO_BODY:
        "<p>To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to " +
        "obtain, verify, and record information that identifies each person who opens an account. What this means for you: When you open an account, " +
        "we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your " +
        "driver's license or other identifying documents.</p>" +
        "<p>You must have an address located within the United States, U.S. Territories, U.S. Commonwealths, or a U.S. Military Address to open an account.</p>",
    FINANCING_APP_PENDING_MSG:
        "The decision on your application is currently being reviewed. If approved for the Stearns & Foster Credit Card, you will receive your card " +
        "in the mail in the next 7–10 days. If denied, you will receive written notification with our reasons for denial in the mail within 30 days. " +
        "If you have any questions about your application status, please call 1-877­-805­-7744, Monday – Friday 9 am – 7 pm Eastern Time.",
    FINANCING_APP_SUBMIT_ERROR:
        "An unexpected error occurred. Please review your information and try again in a few minutes. If the problem " +
        "continues, please contact us at 1-800-459-8451.",
    FINANCING_RETURN_TO_CHECKOUT: "Return to Checkout",
    FINANCING_RETURN_TO_SITE: "Return to Stearns & Foster.com",
    FINANCING_WELCOME_MSG: t`Your new credit card unlocks exclusive financing offers from Stearns & Foster. Simply enter your new credit card number at checkout.`,

    FINANCING_FINANCING_ERROR:
        "That does not appear to be a valid Stearns & Foster Credit Card number. Please verify the entered information is correct.",
    FINANCING_DEFAULT_PLAN_TERM_MONTHS: getPageSetting(
        "financing-default-plan-term-months",
    ),
    FINANCING_DEFAULT_PLAN_APR: getPageSetting("financing-default-plan-apr"),
    FINANCING_DEFAULT_PLAN_THRESHOLD: getPageSetting(
        "financing-default-plan-threshold",
    ),
    FINANCING_DEFAULT_PLAN_SUPERSCRIPT: getPageSetting(
        "financing-default-plan-superscript",
    ),
    FINANCING_MIN_AMOUNT: "500.00",
    FINANCING_TERMS_PDF:
        "https://l.tempurpedic.com/wellsfargo-terms/web/viewer.html?pdf=stearns.pdf",
    FINANCING_TECHNICAL_REQS_LINK:
        "https://www.wellsfargo.com/help/wfonline/hardware_software_req.jhtml",
    FINANCING_BASKET_FINE_PRINT: getPageSetting("basket-financing-fine-print"),
    FINANCING_CHECKOUT_FINE_PRINT: getPageSetting(
        "checkout-financing-fine-print",
    ),
    FINANCING_CHECKOUT_FINE_PRINT_MOBILE: getPageSetting(
        "checkout-financing-fine-print-mobile-content",
    ),
    FINANCING_CONSENT_BODY:
        "Please read the Consent for Electronic Disclosure of Special Terms and APR Information below." +
        "<br /><br />" +
        "If you consent to receive the Special Terms and APR Information disclosure electronically, please check the box below." +
        "<br /><br />" +
        "Consent for Electronic Disclosure of Special Terms and APR Information" +
        "<br /><br />" +
        "Consent for Electronic Disclosure. The law requires that certain information be provided to you “in writing” in" +
        "connection with your purchase under special terms. The law allows this information to be provided to you" +
        "electronically, instead, with your prior consent. So before we provide these disclosures electronically," +
        "you must review the following information and consent to receive the Special Terms and APR Information disclosure" +
        "electronically. Your consent applies only to this transaction. The length of the special terms period is the" +
        "time period indicated for the special terms you have selected." +
        "<br /><br />" +
        "Withdrawal of Consent. You have the right to withdraw your consent at any time prior to final submission of" +
        "this purchase at no cost to you. If you withdraw your consent, you will be unable to make your purchase online" +
        "using the special terms you have selected. You withdraw your consent by exiting your web browser without" +
        "completing this purchase, selecting the back button, or by selecting some other method of payment." +
        "<br /><br />" +
        "Hardware and Software Requirements for Access and Retention. To retain the Special Terms and APR Information" +
        "disclosure, you will need an internet browser capable of saving or printing this web page. You will also need" +
        "a printer if you wish to print out and retain the Special Terms and APR Information disclosure on paper, and" +
        "a method of electronic storage if you wish to retain the disclosure in electronic form. If you would like a" +
        "paper copy of the Special Terms and" +
        "<br /><br />" +
        "APR Information disclosure, you may contact Stearns & Foster " +
        'by calling <a data-phone-link-label="1-866-783-2767" data-phone-link="1-866-783-2767" href="tel:18667832767">1-866-783-2767</a> from Monday –' +
        "Friday 9:00 AM – 6:00 PM Eastern Time to request a paper version at no charge." +
        "<br /><br />" +
        "Method of Consent. You consent to receive the Special Terms and APR Information disclosure electronically" +
        "by checking the box below and clicking on the “Place Order” button." +
        "<br /><br />",
    FINANCING_PREQUAL_CONSENT_MSG: t`By checking this box, I understand this is not a
                            credit card application and is only being used to
                            see if any pre-qualified offers are available. I
                            also understand Wells Fargo Bank, N. A. will conduct
                            a soft pull of my credit and this will not affect
                            my credit score. I also understand that Wells Fargo
                            may share my name, address email address and
                            pre-qualification status with Stearns & Foster so they
                            may send or email me information regarding my
                            pre-qualification status.`,
    CHECKOUT_ERROR_FALLBACK:
        `An error occurred while placing your order. Please verify the information is correct, and resubmit your order. ` +
        `If you are still experiencing issues, you may call us at WEBSITE_SUPPORT_NUMBER.`,
    REJECT_MSG_FINANCING:
        `There was an issue processing your financing payment. Please take the following steps to complete your order: <ul>` +
        `<li>Step 1 - Please verify your information is accurate, correct any errors, and resubmit your order.</li>` +
        `<li>Step 2 - If you are still experiencing issues, please call us at WEBSITE_SUPPORT_NUMBER and provide reference number ORDER_ID. ` +
        `We’re here WEBSITE_SUPPORT_CONTACT_HOURS to assist you.</li>`,
    REJECT_MSG_CYBERSOURCE:
        `Your payment has been declined. Please take the following steps to complete your order: <ul>` +
        `<li>Step 1 - Verify that your payment and billing information is accurate, correct any errors and resubmit your order</li>` +
        `<li>Step 2 - If you believe your payment and billing information is correct, please contact your financial institution</li>` +
        `<li>Step 3 - If you are still experiencing issues, you may call us at WEBSITE_SUPPORT_NUMBER</li></ul>`,
    REJECT_MSG_CASH:
        `There was an issue processing your payment. Please take the following steps to complete your order: <ul>` +
        `<li>Step 1 - Please verify your information is accurate, correct any errors, and resubmit your order.</li>` +
        `<li>Step 2 - If you are still experiencing issues, please call us at WEBSITE_SUPPORT_NUMBER.` +
        `We’re here WEBSITE_SUPPORT_CONTACT_HOURS to assist you.</li>`,
});
