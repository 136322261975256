// sort-imports-ignore
/* eslint-disable import/first */
// Import polyfills and setup error reporting system
import "./src/_bootstrap";
import { Provider, getServerData, getTemplate } from "@reactivated";
import { initPage } from "@thelabnyc/thelabui/src/index";
import {
    registerChatConnector,
    AdaChatConnector,
} from "tsi-common-react/src/apps/chat/index";

import {
    sendGTMUserHash,
    kuidListeners,
    trackPageView,
} from "tsi-common-react/src/utils/analytics";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { strToBool } from "tsi-common-react/src/utils/format";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import { initWidgets } from "tsi-common-react/src/widgets";

// Remove no-js class from HTML tag
const htmlElem = document.querySelector("html");
if (htmlElem) {
    htmlElem.classList.remove("no-js");
}

// Load CSS
import "./src/styles/main.scss";

// Update the user hash in GTM on page load
window.onload = () => {
    sendGTMUserHash();
};
kuidListeners();

// Set tsi-common-react configuration
import "./src/localization";

// This is the main entry point for new-style thelab-ui / reactivated
// components (which support SSR)
initPage({
    Provider,
    getServerData,
    getTemplate,
}).catch((e) => {
    console.error(e);
});

// chat settings
const chatEnabled = strToBool(getPageSetting("chat-enabled", "false"));
if (chatEnabled) {
    const hideChat = strToBool(getPageSetting("chat-hide-widget", "true"));
    const chatConnector = new AdaChatConnector({
        settings: {
            handle: "tsi-stearns-foster",
        },
    });
    registerChatConnector(chatConnector);
    chatConnector.init({
        hideChat: hideChat,
    });
}

// Configure analytics
onDOMContentLoaded.on(() => {
    trackPageView();
});

// Load Site Components
initWidgets();

import "./src/helpers/index";

import "./src/Checkout/index";
import "./src/Common/index";
import "./src/Orders/index";
import "./src/ProductDetail/index";
import "./src/Reviews/index";
import "./src/Favorites/index";
import "./src/RetailLocator/index";
import "./src/Offers/index";
import "./src/ProductGrid/index";
import "./src/ProductCompare/index";
import "./src/TempurLoveGrid/index";
import "./src/UGCHomepage/index";
