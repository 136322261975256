import * as wishlistsAPI from "tsi-common-react/src/api/wishlists";
import { Dispatchers } from "tsi-common-react/src/apps/checkout/dispatchers";

import { LIST_FAVORITES } from "./constants";

export class Loaders {
    private readonly dispatchers: Dispatchers;

    constructor(dispatchers: Dispatchers) {
        this.dispatchers = dispatchers;
    }

    loadFavorites() {
        // Load wishlist lines, but allow this to fail gracefully
        const loadingFavorites = wishlistsAPI.listLines(LIST_FAVORITES);
        console.debug("Loading favorites...");
        return Promise.all([loadingFavorites]).then(([favoritesList]) => {
            console.log(favoritesList);
            this.dispatchers.updateWishlist(LIST_FAVORITES, favoritesList);
            console.debug("Loaded favorites.");
            return favoritesList;
        });
    }
}
