import React from "react";
import { IWishListLine } from "tsi-common-react/src/models/catalogue.interfaces";

import { WishListLine } from "./WishListLine";

interface IProps {
    lines: IWishListLine[];
    removeWishlistLine: (lineURL: string) => Promise<IWishListLine[]>;
}

interface IState {}

export class FavoritesList extends React.Component<IProps, IState> {
    private renderLineList() {
        return this.props.lines.map((line) => {
            return (
                <WishListLine
                    key={`${line.product.id}`}
                    {...this.props}
                    line={line}
                />
            );
        });
    }

    private renderEmptyBasket() {
        return <p>This list is empty.</p>;
    }

    render() {
        const isEmpty = this.props.lines.length <= 0;
        const content = isEmpty
            ? this.renderEmptyBasket()
            : this.renderLineList();
        return <React.Fragment>{content}</React.Fragment>;
    }
}
