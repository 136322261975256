import * as wishlistsAPI from "tsi-common-react/src/api/wishlists";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

import { placeLinkNumber } from "./index";

const LIST_FAVORITES = "favorites";

const buildFavoritesLinkNumber = () => {
    const favoritesList = wishlistsAPI.listLines(LIST_FAVORITES);

    return Promise.all([favoritesList]).then(([favorites]) => {
        placeLinkNumber(favorites.length, "favorites-link");
    });
};

onDOMContentLoaded.on(() => {
    buildFavoritesLinkNumber();
});
