import "tsi-common-react/src/blocks/columns-carousel-block";
import "tsi-common-react/src/blocks/wistia-background-video";

import "./accordion";
import "./carousel";
import "./detail-slider";
import "./favorites-link";
import "./featured-products";
import "./finance-faq";
import "./gallery";
import "./helpers";
import "./lazyloading";
import "./menu";
import "./reviews-link";
import "./survey";
import "./tabs";

export const CURRENT_CLASS = "--is-current";

export const placeLinkNumber = (
    num: number | string,
    cssClassName: string,
): void => {
    num = String(num);
    const links = document.getElementsByClassName(cssClassName);
    for (let i = 0; i < links.length; i++) {
        links[i].insertAdjacentHTML("beforeend", "<em>" + num + "</em>");
    }
};

export const clearSelectedClass = (
    elem: HTMLElement,
    targetClass: string,
): void => {
    Array.from(elem.getElementsByClassName(targetClass)).forEach((subBlock) => {
        subBlock.classList.remove(CURRENT_CLASS);
    });
};
