import React from "react";
import { Provider } from "react-redux";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";

import { rehydratingStore, store } from "../store";

// Render Favorites
dynamicPlaceComponent('[data-place-react="favorites"]', async () => {
    const { Favorites } = await import("./Favorites");
    await rehydratingStore;
    return (
        <Provider store={store}>
            <Favorites />
        </Provider>
    );
});

// Render wish list icon
dynamicPlaceComponent('[data-place-react="favorites-icon"]', async () => {
    const { FavoritesIcon } = await import("./FavoritesIcon");
    return (
        <Provider store={store}>
            <FavoritesIcon />
        </Provider>
    );
});
