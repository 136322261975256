import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

import { CURRENT_CLASS, clearSelectedClass } from "./index";

export const registerEventListeners_DetailSliderHeaders = () => {
    Array.from(
        document.querySelectorAll<HTMLElement>(".detail-slider"),
    ).forEach((elem) => {
        Array.from(elem.getElementsByClassName("sub-block-nav")).forEach(
            (subBlock) => {
                const header = subBlock.getElementsByTagName("header")[0];

                if (header) {
                    header.addEventListener("click", (e) => {
                        e.preventDefault();
                        const slideID = header.parentElement
                            ? header.parentElement.dataset.key
                            : null;
                        const slide = elem.querySelector(
                            `.sub-block[data-key="${slideID}"]`,
                        );

                        if (
                            slide &&
                            header.parentElement &&
                            slide.parentElement
                        ) {
                            clearSelectedClass(elem, "sub-block-nav");
                            clearSelectedClass(elem, "sub-block");
                            header.parentElement.classList.add(CURRENT_CLASS);
                            slide.classList.add(CURRENT_CLASS);
                        }
                    });
                }
            },
        );
    });
};

onDOMContentLoaded.on(() => {
    registerEventListeners_DetailSliderHeaders();
});
