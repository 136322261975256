import React from "react";
import { connect } from "react-redux";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import { storeSelector } from "tsi-common-react/src/apps/retail/selectors";
import { ILocation } from "tsi-common-react/src/models/location.interfaces";

import { IReduxState } from "../../reducers.interfaces";

interface IOwnProps {
    type?: "bare-count" | "sentence";
}

interface IReduxProps {
    location: ILocation | null;
    numStores: number;
}

interface IProps extends IOwnProps, IReduxProps {}

const RetailLocatorStoreCountContainer = (props: IProps) => {
    if (props.type === "sentence") {
        if (!props.location) {
            return null;
        }
        return (
            <>
                {props.numStores} retailers near{" "}
                {props.location.formatted_address_long}
            </>
        );
    }
    return <>{props.numStores}</>;
};

const mapStateToProps = (rootState: IReduxState): IReduxProps => {
    return {
        location: preferredLocationSelector(rootState),
        numStores: storeSelector(rootState).length,
    };
};

export const RetailLocatorStoreCount = connect(mapStateToProps)(
    RetailLocatorStoreCountContainer,
);
