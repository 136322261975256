import React from "react";
import {
    BasketLineImage,
    BasketLinePrice,
    BasketLineTitle,
    IBasketLineBaseProps,
} from "tsi-common-react/src/apps/checkout/components/BasketLineBase";
import { IWishListLine } from "tsi-common-react/src/models/catalogue.interfaces";
import {
    PriceType,
    getOptionValueDisplayName,
    getProductPrice,
} from "tsi-common-react/src/utils/catalogue";

interface IProps extends IBasketLineBaseProps<IWishListLine> {
    removeWishlistLine: (lineURL: string) => Promise<IWishListLine[]>;
}

interface IState {}

export class WishListLine extends React.Component<IProps, IState> {
    private readonly onRemoveLine = () => {
        this.props.removeWishlistLine(this.props.line.url);
    };

    private buildRemoveButton() {
        return (
            <button
                onClick={this.onRemoveLine}
                className="basket-line__details__button basket-line__details__button--remove al-basket-line__button--remove"
            >
                [Remove from favorites]
            </button>
        );
    }

    render() {
        const product = this.props.line.product;
        const rootProduct = product.parent || product;
        let optionFeel = "";
        let optionSize = "";
        if (
            product &&
            product.attributes &&
            product.attributes.option_size &&
            product.attributes.option_feel
        ) {
            optionFeel = getOptionValueDisplayName(
                product.attributes.option_feel.name,
                product.attributes.option_feel.value,
            );
            optionSize = getOptionValueDisplayName(
                product.attributes.option_size.name,
                product.attributes.option_size.value,
            );
        }
        return (
            <div className="favorites-line">
                <BasketLineImage product={product} />
                <div className="details">
                    <BasketLineTitle product={rootProduct} />
                    <p>
                        {optionSize} &ndash; {optionFeel}
                    </p>
                    {product.price && (
                        <BasketLinePrice
                            unitPrice={product.price}
                            linePriceInclDiscounts={getProductPrice(
                                product.price,
                                {
                                    priceType: PriceType.COSMETIC_EXCL_TAX,
                                    includePostDiscountAddons: true,
                                    quantity: 1,
                                },
                            )}
                            postDiscountAddons={
                                product.price.post_discount_addons
                            }
                            quantity={1}
                        />
                    )}
                    {this.buildRemoveButton()}
                </div>
            </div>
        );
    }
}
